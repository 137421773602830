<template>
  <div class="page-content">
    <div class="container">
      <div class="ae add-employee red-theme mb-3">
        <h3 class="ae__title">Поиск новых сотрудников</h3>
        <div>
          <v-form v-model="valid">
            <div class="row">
              <div class="col-12 col-md-4 mb--sm">
                <Select
                  label="Выберите категорию услуги"
                  :items="normalizeCategories"
                  item-value="id"
                  item-text="name"
                  v-model="formData.category"
                />
              </div>
              <div class="col-12 col-md-4 mb--sm">
                <Select
                  label="Выберите категорию услуги"
                  item-value="id"
                  item-text="name"
                  :items="normalizeResponsibilities"
                  v-model="formData.responsibility"
                  :disabled="!formData.category"
                />
              </div>
              <div class="col-12 col-md-4 mb--sm">
                <button
                  type="button"
                  class="btn btn--prime btn--fs btn-search"
                  @click="onSubmit"
                >
                  Поиск
                  <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'btn-search__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M25.076 20.489l-4.279-4.28c.778-1.556 1.297-3.371 1.297-5.187A11.013 11.013 0 0011.07 0 11.013 11.013 0 00.05 11.022 11.013 11.013 0 0011.07 22.045c1.816 0 3.631-.519 5.187-1.297l4.28 4.28c1.296 1.296 3.241 1.296 4.538 0a3.38 3.38 0 000-4.54zM11.071 19.45a8.412 8.412 0 01-8.429-8.429 8.412 8.412 0 018.43-8.428 8.412 8.412 0 018.428 8.428 8.412 8.412 0 01-8.429 8.43z"/></svg>
                </button>
              </div>
            </div>
          </v-form>
        </div>
      </div>

      <template v-if="loading">
        <v-progress-linear indeterminate></v-progress-linear>
      </template>

      <template v-else>
        <div
          v-for="(provider, i) in normalizeProviderList"
          class="ae add-employee employee-search red-theme"
          :key="i"
        >
          <div class="row">
            <div class="col-12">
              <BlockPerson
                class="block-person--lg"
                :person="provider.blockPerson"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="es es-box-bb">
                <div class="ic-ell es-ell">
                  <div class="ic-ell__icon">
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                  </div>
                  <div class="es__text">
                    <strong>Пол:</strong>
                    {{ provider.gender }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="es es-box-bb">
                <div class="ic-ell es-ell">
                  <div class="ic-ell__icon">
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                  </div>
                  <div class="es__text">
                    <strong>Дата рождения:</strong>
                    {{ provider.birthday }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="es es-box-bb">
                <div class="ic-ell es-ell">
                  <div class="ic-ell__icon">
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                  </div>
                  <div class="es__text">
                    <strong>О себе:</strong>
                    {{ provider.about }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="es es-box-bb">
                <div class="ic-ell es-ell">
                  <div class="ic-ell__icon">
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                  </div>
                  <div class="es__text">
                    <strong>График работы:</strong>
                    {{ provider.timetable }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="provider.services.length">
            <div class="row">
              <div class="col-12">
                <h3 class="es-title">Оказываемые услуги</h3>
              </div>
            </div>

            <div class="es-services">
              <div class="row">
                <div
                  v-for="(service, key) in provider.services"
                  class="col-12 col-md-6"
                  :key="key"
                >
                  <span class="es__sub es__sub-title">{{ service.category_name }}</span>
                  <span class="es__sub es__sub-desc">{{ service.responsibility_name }}</span>
                </div>
              </div>
            </div>
          </template>

          <template v-if="provider.certificate.length">
            <div class="row">
              <div class="col-12">
                <h3 class="es-title">
                  Фото документов подтверждающих квалификацию
                </h3>
              </div>
            </div>
          </template>

          <form class="es-form">
            <div class="row mb-5">
              <template v-if="provider.certificate.length">
                <div
                  v-for="(certificate, i) in provider.certificate"
                  :key="i"
                  class="col-12 col-md-6"
                >
                  <div
                    class="ef-form__upload"
                    @click="$modal.show('ViewPhoto', { items: provider.certificate, index: i })"
                  >
                    <div class="es-form__wrapp">
                      <img :src="certificate" alt="" class="es-form__pic">
                      <div class="es-form__check">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'es-form__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.111 23.247c.762 0 1.359-.305 1.778-.927L22.772 3.988c.304-.457.431-.876.431-1.27 0-1.04-.762-1.79-1.828-1.79-.736 0-1.18.255-1.625.966L9.06 18.804l-5.458-6.881c-.432-.52-.876-.762-1.511-.762-1.08 0-1.854.762-1.854 1.816 0 .457.153.888.546 1.345l6.564 8.062c.495.596 1.04.863 1.764.863z"/></svg>
                      </div>
                    </div>
                    <p class="es-form__text">Сертификат</p>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="!provider.is_sent_invite"
              class="row justify-content-center"
            >
              <div class="col-12 col-md-6">
                <button
                  type="button"
                  class="btn btn--prime btn--fs"
                  @click="chooseService(i)"
                >Пригласить к сотрудничеству</button>
              </div>
            </div>
            <div
              v-else
              class="row justify-content-center"
            >
              <div class="col-12 col-md-6">
                <div class="ic-ell justify-content-center">
                  <div class="ic-ell__icon ic-ell__icon--red">
                    <svg width="10" height="8" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg-white'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.075L8.785.312l.736.761-6.5 6.5L0 4.552l.762-.762 2.26 2.285z"/></svg>
                  </div>
                  <p class="es-invited">Приглашен(-а) к сотрудничеству</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template v-if="totalCountPages">
        <Pagination
          v-model="page"
          :length="totalCountPages"
        ></Pagination>
      </template>

    </div>
  </div>
</template>

<script>

import Select from "@component/UI/Form/Select";
import BlockPerson from "@component/Module/BlockPerson";
import {mapGetters, mapState, mapActions } from "vuex";
import Pagination from "@component/UI/Pagination/Pagination";
import SearchEmployeeModel from '@component/Module/SearchEmployeeModel';

export default {
  name: "EmployeeSearch",
  components: {
    Select,
    BlockPerson,
    Pagination
  },
  data() {
    return {
      formData: {
        category: '',
        responsibility: '',
      },
      loading: false,
      code: null,
      valid: false,
      category: '',
      responsibility: '',
      providerList: [],
      page: 1,
      pageSize: 5,
      totalCountPages: null,
      totalCountItems: null,
    };
  },
  watch: {
    'formData.category'() {
      this.formData.responsibility = '';
    },
    page() {
      this.getProviderList();
    }
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories,
      genders: state => state.enums.genders,
      repeats: state => state.enums.repeats
    }),
    ...mapGetters('enums', ['services']),
    ...mapGetters('user', ['company']),
    normalizeProviderList() {
      return this.providerList.map(provider => {
        return new SearchEmployeeModel(provider, this.genders, this.repeats);
      });
    },
    normalizeCategories() {
      return [
        { id: '', name: 'Не выбрано'},
        ...this.categories
      ];
    },
    normalizeResponsibilities() {
      return [
        { id: '', name: 'Не выбрано'},
        ...this.services.filter(service => service.category === this.formData.category)
      ];
    },
  },
  methods: {
    ...mapActions('enums', ['getRepeats']),
    chooseService(i) {
      this.$modal.show('ServiceProvide',
        {
          title: 'Выберите услугу',
          text: 'Необходимо выбрать услугу, которую будет предоставлять будущий сотрудник'
        },
        {
          submit: (formData) => this.sendInvite(formData, i)
        }
      );
    },
    sendInvite(formData, i) {

      this.$modal.show('Confirm', {
        title: 'Подтвердите ваш выбор, исполнитель получит приглашение',
        //text: ''
      })
        .then((res) => {
          if (!res) return;
          const provider = this.providerList[i];
          const params = {
            from_company: this.company.id,
            to_user: provider.id,
            ...formData
          };

          RequestManager.Invite.sendRegisteredUser(params)
            .then(() => {
              provider.is_sent_invite = true;
            });
        });

    },
    onSubmit() {
      this.page = 1;
      this.responsibility = this.formData.responsibility;
      this.category = this.formData.category;
      this.getProviderList();
    },
    async getProviderList() {
      this.loading = true;
      RequestManager.Invite.getRegisteredUserList({
        page: this.page,
        page_size: this.pageSize,
        category: this.category,
        responsibility: this.responsibility
      })
        .then(({ results, count }) => {
          this.totalCountItems = count;
          this.providerList = results;
          this.totalCountPages = Math.ceil(count / this.pageSize);
        })
        .finally(() => this.loading = false);
    },
  },
  async mounted() {
    this.getRepeats();
    await this.getProviderList();
  }
};
</script>

<style lang="scss" scoped>

.add-employee {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 47px 26px;
}

.ae__title {
  font-size: 25px;
  color: var(--color-prime);
  margin-bottom: 12px;
}

.ae-btn-wrapp {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @include respond-to('md') {
    flex-wrap: nowrap;
    margin-top: 40px;
  }
}

.btn-search {
  position: relative;
  font-weight: 600;
  font-size: 19px;
}

.btn-search__svg {
  position: absolute;
  right: 15px;
  width: 26px;
  height: 26px;
  fill: #FFFFFF;
}

.employee-search {
  & + .employee-search {
    margin-top: 20px;
  }
  .es-box-bb {
    padding: 8px 0 15px 0;
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  }

  .es__bold-text {
    font-weight: 600;
    font-size: 15px;
    color: #020202;
  }

  .es__text {
    font-weight: 400;
    font-size: 15px;
    color: #020202;
  }

  .es-ell {
    display: flex;
    align-items: baseline;
  }

  .es-title {
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 27px;
  }

  .es-services {
    background: #FFFFFF;
    border: 1px solid rgba(118, 118, 128, 0.2);
    overflow: hidden;
    padding: 20px 15px;
    border-radius: 8px;
  }

  .es__sub {
    font-size: 17px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .es__sub-title {
    color: rgba(0, 0, 0, 0.4);
  }

  .es__sub-desc {
    color: #000000;
  }

  .ef-form__upload {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .es-form__wrapp {
    position: relative;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
  }

  .es-form__check {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .es-form__pic {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .es-form__icon {
    fill: #35C759;
    width: 30px;
    height: 30px;
  }

  .ef-form__text {
    font-size: 17px;
  }

  .es-invited {
    font-weight: 600;
    font-size: 19px;
    @media(max-width: 768px) {
      font-size: 16px;
    }
  }

  .ic-ell__icon--red {
    border-color: var(--color-prime);
    background: var(--color-prime);
  }

  .ic-ell__svg-white {
    width: 10px;
    height: 8px;
    fill: #FFFFFF;
  }

}
</style>

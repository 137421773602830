<template>
  <div
    v-if="$attrs.length > 1"
    class="pagination"
  >
    <v-pagination
      v-bind="$attrs"
      v-on="$listeners"
      circle
    ></v-pagination>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      page: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

::v-deep {
  .v-pagination .v-pagination__item {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757579;
    font-weight: 600;
    font-size: 16px;
    box-shadow: none;
  }

  .v-pagination__navigation {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    &:hover, &:focus, &:active {
      background: var(--color-prime);

      .v-icon {
        color: #FFFFFF;
      }
    }
  }

  .v-pagination .v-pagination__item--active {
    background: var(--color-prime);
    color: #FFFFFF;
    box-shadow: none;
  }

  .v-pagination .v-pagination__navigation.v-pagination__navigation--disabled {
    background: transparent;
  }
}


</style>

import BlockPersonModel from '@component/Module/BlockPersonModel';
import formatTime from '@filter/modules/formatTime';

export default class SearchEmployeeModel {
  constructor(person, genders, repeats) {
    this.blockPerson = new BlockPersonModel({
      first_name: person.first_name,
      last_name: person.last_name,
      photo: person.photo
    });
    this.birthday = person.birthday;
    this.gender = person.gender && genders.find(el => el.key === person.gender).value;
    this.services = person.service;
    this.about = person.about;
    this.is_sent_invite = person.is_sent_invite;
    this.certificate = person.certificate.reduce((result, { image }) => {


      if (!image?.startsWith('https')) {
        image = process.env.VUE_APP_URL + image;
      }

      result.push(image);
      return result;
    }, []);
    this.timetable = person.timetable.reduce((result, { weekday, start_time, end_time }) => {
      let day = repeats.find(({ key }) => weekday === key);
      if (day) {
        result.push(`${day.value}(c ${formatTime(start_time)} до ${formatTime(end_time)})`);
      }
      return result;
    }, []).join(', ');
  }
}
